import React, { createContext, useState, useContext, Dispatch, useEffect } from 'react'

interface IChainContext {
  selectedChain?: string
  onSelectChain: (chain: string) => void
}

const ChainContext = createContext({} as IChainContext)

const ChainProvider = ({ children }) => {
  const [selectedChain, setSelectedChain] = useState<string | undefined>()

  /* istanbul ignore next */
  const onSelectChain = (chain: string) => {
    setSelectedChain(chain)
  }

  return (
    <ChainContext.Provider value={{ selectedChain, onSelectChain }}>
      {children}
    </ChainContext.Provider>
  )
}

export const useChain = () => {
  return useContext(ChainContext)
}

export { ChainContext, ChainProvider }
