import { DELETE_DEALS_URL } from '@constants'
import { MutationFunction, useMutation } from '@tanstack/react-query'
import { useAuthenticationService } from 'admin-portal-shared-services'
import { generateUUID } from '../../../service'
import { useApiService } from '../../api'

type DeleteTableItemArgs = {
  ids: string[]
  status: string
  vendor?: string | null
}

export const useDeleteTableItem = () => {
  const auth = useAuthenticationService()
  const api = useApiService()
  const headers = {
    Authorization: auth.getAuthHeader(),
    country: auth.getCountryB2C(),
    email: auth.getUserEmailB2C().toLowerCase(),
    requestTraceId: generateUUID()
  }
  const deleteTableItem: MutationFunction<void, DeleteTableItemArgs> = async ({
    ids,
    status,
    vendor = null
  }) => {
    const updatedHeaders = {
      ...headers,
      ...(vendor ? { vendor } : {})
    }
    await api.delete({
      url: DELETE_DEALS_URL,
      config: {
        headers: updatedHeaders,
        params: {
          ids: ids.join(','),
          status
        }
      }
    })
  }
  return useMutation(deleteTableItem)
}
