import { DealContent } from '@interfaces'

export type TTableItem = {
  chain: string
  product: string
  type: string
  ttvNow: number
  ttvWas: number | string
  ttcNow: number
  ttcWas: number
  startDate: string
  endDate: string
  status: string
  actions: string
  acceptedAt: string
  image: string
  qtdMax: string
  qtdMin: string
  vendorName: string
  potentialRefund: string | undefined
  refundPerDeal: string | undefined
  refundEarned: string | undefined
  deliveredQtd: string | undefined
  remainingQtd: string | undefined
  mechanic: string | undefined
  refund: string | undefined
  ean: string | undefined
  observations: string | undefined
}

/* istanbul ignore next */
export const tableDataNormalizer = (data: DealContent[]) => {
  const result: TTableItem[] = data?.map((item) => ({
    chain: item.chainName,
    product: item.item.name,
    type: item.type,
    ttvNow: parseFloat(item.ttvNow),
    ttvWas: item.ttvWas ? parseFloat(item.ttvWas) : '-',
    ttcNow: parseFloat(item.ttcNow),
    ttcWas: parseFloat(item.ttcWas),
    startDate: item.from,
    endDate: item.to,
    status: item.status,
    actions: item.id,
    acceptedAt: item.acceptedAt,
    image: item.item.image,
    qtdMax: item.qtdMax,
    qtdMin: item.qtdMin,
    potentialRefund: item.refundEarned?.potential,
    refundPerDeal: item.refundEarned?.perDeal,
    refundEarned: item.refundEarned?.earned,
    deliveredQtd: item.quantity?.delivered,
    remainingQtd: item.quantity?.remaining,
    mechanic: item.mechanic,
    refund: item.refund,
    ean: item.item.id,
    observations: item.observations,
    vendorName: item.vendorName
  }))

  return result
}
