import enUS from './languages/en-ZA.json'
import enMX from './languages/es-419.json'
import ptBR from './languages/pt-BR.json'

export type Language = 'en-ZA' | 'es-419' | 'pt-BR'

export const defaultNS = 'common'

export const resources = {
  'en-ZA': enUS,
  'es-419': enMX,
  'pt-BR': ptBR
} as const
