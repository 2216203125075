export const MAX_FILE_SIZE = 2 * 1024 * 1024 // 2MB
export const MAX_FILE_SIZE_PDF = 5 * 1024 * 1024 // 5MB
export const ACCEPTED_FILE_TYPES = '.csv, .xlsx'
export const ACCEPTED_FILE_TYPES_PDF = 'application/pdf'

export const ANALYSIS_MAX_ATTEMPTS = 300
export const ANALYSIS_RETRY_DELAY = 3000

export const STATUS = {
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED'
}
