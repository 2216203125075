import { useTranslation } from 'react-i18next';

const useDynamicTranslations = () => {
    const { t } = useTranslation();

    const translate = (namespace: string, key: string) => {
        const translationKey = `${namespace}.${key}`;
        return t(translationKey, key);
    };

    const generalTranslate = (appoint: string, contentItem: string) => {
        return translate(appoint, contentItem);
    }

    const translateAttributeName = (attributeName: string) => {
        return translate('deals.table.header', attributeName);
    };

    const statusTranslations = {
        AWAITING_ACCEPTANCE: 'awaiting_acceptance', 
        ENDED: 'ended',
        IN_PROGRESS: 'in_progress',
        NOT_ACCEPTED: 'not_accepted',
        PLANNED: 'planned'
    };

    const translateStatus = (status: string) => {
        const normalizedStatus = statusTranslations[status] || status.toLowerCase();
        return translate('deals.table.status', normalizedStatus);
    };

    return { translateAttributeName, translateStatus, generalTranslate };
};

export default useDynamicTranslations;
