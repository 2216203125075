import { Home, Tag } from '@hexa-ui/icons'
import { Namespaces } from 'i18next'
import { ElementType, Suspense, lazy } from 'react'
import { PageLoader } from '../components/PageLoader'

export type Modules = 'DEALS' | 'HOME'
export type ModulesPages = Modules | 'DEALS_CREATE' | 'OFFERS_PAGE'

type Route = {
  path: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Element: any
  children?: Route[]
}

type RoutesProps = Record<ModulesPages, Route>

type SidebarProps = Record<Modules, { id: Namespaces; path: string; icon: ElementType }>

export const DealsPage = lazy(() => import('../pages/Deals/Deals'))
export const DealsCreatePage = lazy(() => import('../pages/Deals/Create/Create'))
export const DealsV2Page = lazy(() => import('../pages/Offers/Offers'))

export const LINK_ADMIN_ROUTE = 'link-admin'
export const BASE_NAME = 'deals'

export const homePath = `/${LINK_ADMIN_ROUTE}`
export const dealsPath = `/${BASE_NAME}`
export const dealsCreatePath = `${dealsPath}/create`
export const dealsV2Path = `${dealsPath}/v2`

export const Routes: RoutesProps = {
  HOME: { path: homePath, Element: null },
  OFFERS_PAGE: { path: dealsPath, Element: DealsV2Page },
  DEALS: { path: dealsV2Path, Element: DealsPage },
  DEALS_CREATE: { path: dealsCreatePath, Element: DealsCreatePage }
}

export const Sidebar: SidebarProps = {
  HOME: {
    id: 'common',
    path: `/${LINK_ADMIN_ROUTE}`,
    icon: () => <Home />
  },
  DEALS: {
    id: 'common',
    path: dealsPath,
    icon: () => <Tag />
  }
}

export const sidebar = [Sidebar.DEALS]

export const modules = [Routes.DEALS, Routes.DEALS_CREATE, Routes.OFFERS_PAGE]

export const routesConfig = modules.map(({ path, Element }) => ({
  path,
  element: (
    <Suspense fallback={<PageLoader />}>
      <Element />
    </Suspense>
  )
}))
